<template>
  <main id="main-container">
    <div
      class="bg-image"
      style="background-image: url('/static/media/photos/photo34@2x.jpg')"
    >
      <div class="row mx-0 bg-pulse-op">
        <div
          class="hero-static col-md-6 col-xl-8 d-none d-md-flex align-items-md-end"
        >
          <div class="p-4">
            <p class="fs-3 fw-semibold text-white">
              <i class="fa fa-bell opacity-50"></i> Celebrates
              <span class="link-fx text-white">You found your password</span>!
            </p>
            <p class="text-white-75 fw-medium">
              Copyright &copy; <span data-toggle="year-copy">2022</span>
            </p>
          </div>
        </div>
        <div
          class="hero-static col-md-6 col-xl-4 d-flex align-items-center bg-body-extra-light"
        >
          <div class="content content-full">
            <div class="px-4 py-2 mb-4">
              <router-link class="link-fx fw-bold" to="/">
                <i class="fa fa-fire"></i>
                <span class="fs-4 text-body-color">Emo-Tool</span>
              </router-link>
              <h1 class="h3 fw-bold mt-4 mb-2">重新设置密码</h1>
              <h2 class="h5 fw-medium text-muted mb-0">请输入你的新密码</h2>
            </div>
            <form class="js-validation-lock px-4" onsubmit="return false;">
              <div class="form-floating mb-4">
                <input
                  type="text"
                  class="form-control"
                  id="password"
                  v-model="pwd.password"
                  placeholder="输入你的新密码"
                />
                <label class="form-label" for="password">新密码</label>
              </div>
              <div class="form-floating mb-4">
                <input
                  type="text"
                  class="form-control"
                  id="repass"
                  v-model="pwd.newPassword"
                  placeholder="请确认你的密码"
                />
                <label class="form-label" for="repass">确认密码</label>
              </div>
              <div class="mb-4">
                <button
                  type="submit"
                  class="btn btn-lg btn-alt-primary fw-semibold"
                  @click="user_reset"
                >
                  确认重置密码
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { resetPwd } from "@/api/login";
export default {
  name: "Rest",
  data() {
    return {
      pwd: {
        token: this.$route.query.token,
      },
    };
  },

  methods: {
    user_reset() {
      if (this.pwd.password == null || this.pwd.newPassword == null) {
        this.$message.error("密码不能为空");
        return false;
      }
      if (this.pwd.password !== this.pwd.newPassword) {
        this.$message.error("确认密码和密码不一致");
        return false;
      }

      resetPwd(this.pwd)
        .then((message) => {
          this.$message.success(message);

          setTimeout(() => {
            this.$router.push("/login");
          }, 1000);
        })
        .catch((e) => {
          this.$message.error(e.message);
        });
    },
  },
};
</script>

<style>
@media screen and (max-width: 500px) {
  .el-message-box {
    width: 300px !important;
  }
}

@media screen and (max-width: 500px) {
  .el-message {
    min-width: 300px !important;
  }
}

@media screen and (max-width: 500px) {
  .el-dialog__wrapper .el-dialog {
    width: 300px !important;
  }

  .el-dialog__body {
    padding: 10px 20px !important;
  }

  .el-form-item__label {
    width: 68px !important;
  }

  .el-select,
  .el-input {
    width: 180px !important;
  }
}
</style>
